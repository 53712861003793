$random-color: null;

.dark {
	width: 350px;
	border-radius: 5px;
	background-color: #000;
	color: #fff !important;
	padding: 1.5rem;

	&:hover {
		box-shadow: 0px 0px 10px rgb(182, 178, 178);

		.cardExtraInfo span:first {
			border: 1px dotted #333;
		}

				transition: all 0.3s;
				transform: scale(2);
	}
}


// card-layout样式
.cardBox {
	width: 350px;
	border-radius: 5px;
	// background-color: #f8f8ff;
	padding: 1.5rem;

	&:hover {
		box-shadow: 0px 0px 0px rgb(182, 178, 178);
	}
}


.cardImage {
	// width: 350px;
	border-radius: 1rem 1rem 0 0;
	height: 200px;
	background-color: var(--random-color);
}

.cardHeadingTitle {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.cardSubTitle {
	color: #666;
	margin-bottom: 1rem;
}

.cardExtraInfo {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	&:hover {
		border-color: #1677ff;
		border: 1xp dotted #333;
	}
}

.cardExtraInfo span:first-child {
	border: 1px dotted #333;
	padding: 0.3rem;
	margin-left: 0;
	border-radius: 5px;
}

.cardExtraInfo span:last-child {
	padding: 0.5rem 1rem;
	font-size: 1rem;
}

.link:hover {
	color: inherit;
}

@media(prefers-colors-scheme: dark) {
	.cardA {
		background-color: green;
	}
}

@media (max-width: 767px) {
	.carda {
		width: auto !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {

	/* 适用于小型平板设备的样式规则 */
	.carda {
		width: auto;
	}
}