$dark-color: #fff;

.standardCard {
	width: 22.6vw;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(3px);
	font-family: 'JetBrains Sans';
}

.standardContainer {
	width: 100%;
	height: 100%;
	position: relative;
	background: #ffffff;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.standardBox {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(#29c6cf, 0.19);
	border-radius: 5px;
	z-index: 99;
}

.animation {
	transform: translateZ(0);
	animation: animateSize 400ms forwards;
	/* 添加动画效果 */
}

.animationBackClass {
	animation: animationBack 200ms forwards;
}

@keyframes animateSize {
	from {
		width: 0;
		height: 0;
	}

	to {
		width: 100%;
		height: 100%;
	}
}

@keyframes animationBack {
	from {
		width: 100%;
		height: 100%;
	}

	to {
		width: 0;
		height: 0;
	}
}



// .standardCard:hover {
// 	box-shadow: 0px 0px 10px rgb(182, 178, 178);
// }

.standardCardHead {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	/* border: 1px solid #ffffff; */
}

.standardCardHeaderTitle {
	font-size: 0.99rem;
	font-weight: bold;
	padding: 1rem 1rem 0 1rem;
	color: #29c6cf;
}

.standardCardHeaderSubtitle {
	font-size: 1.3rem;
	margin: 1rem;
	font-weight: bolder;
	color: #333333;
}

.standardCardDescription {
	width: 92%;
	margin: 5px auto;
	padding: 1rem;
	background-color: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(1px);
	/* Use audio to have live conversations with other collaborators directly in your Figma and FigJam files. */
	font-size: 14px;
	/* or 20px */
	letter-spacing: 0.015em;
	color: #333333;
	opacity: 0.8;
	border-radius: 5px;
	word-break: normal;
}

.divider {
	width: 100%;
	height: 1px;
	/*margin: 1rem;*/
	margin-bottom: 1rem;
	background-color: #333333;
	opacity: 0.2;
}

.footer {
	width: 100%;
	height: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	margin: 0 2px;
}

.footer .footerLeft {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.footer .footerRight {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
}

.footer .footerLeft .footerLeftAuthor {
	font-size: 0.5rem;
	margin-bottom: 10px;
	padding-right: 3rem;
	color: #333333;
	opacity: 0.6;
}

.footer .footerLeft .footerLeftDate {
	font-size: 0.5rem;
	margin-bottom: 10px;
	color: #333333;
	opacity: 0.6;
}

.footer .footerRight .footerRightViews {
	font-size: 0.5rem;
	margin-bottom: 10px;
	color: #333333;
	opacity: 0.6;
}

@media (max-width: 700px) {
	.standardCard {
		width: 350px;
	}
}

@media (min-width: 719px) and (max-width: 1023px) {

	/* 适用于小型平板设备的样式规则 */
	.standardCard {
		width: 355px;
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {

	/* 适用于中型的台式设备 */
	.standardCard {
		width: 476px;
	}
}

.dark {
	width: 350px;
	border-radius: 5px;
	background-color: #000;
	color: $dark-color !important;
	padding: 1.5rem;

	.standardCardHeaderSubtitle {
		color: #fff;
	}

	.standardCardDescription {
		color: #fff;
	}

	.footerLeftAuthor .footerRightViews {
		color: #fff;
	}
}